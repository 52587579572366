import Image from 'next/image'
import styles from '../Image.module.css'

type ImageRoundCornersTypes = {
  src: string
  alt?: string
  maxHeight?: string
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down'
}

export default function ImageRoundCorners({
  src,
  alt,
  maxHeight = 'auto',
  objectFit = 'cover',
}: ImageRoundCornersTypes) {
  return (
    <Image
      className={`${styles.Image} ${styles.BorderRadius}`}
      src={src}
      alt={alt}
      width={0}
      height={0}
      sizes="100vw"
      style={{ width: '100%', height: 'auto', maxHeight, objectFit }}
    />
  )
}
